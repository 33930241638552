/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Tipy na výlety"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--80" name={"[[UNIsecname1]]-0"} style={{"backgroundColor":"var(--color-custom-1)","marginBottom":0}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper animS={"3"} style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Tipy na výlety po okolí</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"[[UNIgenericsecname2]]"} style={{"paddingTop":28,"paddingBottom":32,"marginBottom":9}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":1308}} content={"Skanzen Kouřim (6 km) - pravidelné různorodé akce<br>Okruh kolem Kouřimi, Lechův kámen - \"Naučná stezka Stará Kouřim\" <br>Kamenný most Zásmuky (6 km) - vycházka podél Vavřineckého potoka k baroknímu mostu, který sloužil jako kulisa při natáčení pohádky Nesmrtelná teta<br>Radim (11 km) - Zámek a \"Zastávka Radim\" - místo kulturních akcí <br>Zábavní park Bylandia (15 km) - zoo, atrakce pro děti, muzeum a další.. <br>Farma Bláto (15 km) - zvířátka a restaurace <br>Vodní svět Kolín (16 km) - venkovní i vnitřní aquapark pro děti <br>Letní plovárna Kutná Hora (20 km) - příjemné koupání a vyžití pro malé i větší děti <br>Rozhledna Vysoká, zřícenina letohrádku Belvedere a tajemný menhir pro léčbu zad (16 km) <br>Kutná Hora (20 km) a Kostince v Sedlci <br>Zřícenina husitského hradu Sion (26 km) <br>Ovocné sady Bříství - lokální ovoce a zelenina (10 km) - dle otevírací doby<br>Studánka Sudějov (20 km) poutní místo + kostel sv. Anny a studánka se \"zázračnou vodou\"<br>Sázava (25 km) - klášter Sázava (poutní místo) a Sportovní areál Sázavský ostrov <br>Zoo Chleby (30 min) -&nbsp;první oficiální porevoluční nestátní zoologická zahrada v ČR <br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":28}} name={"0g59huyoyif"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Doporučujeme a podporujeme<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":1206}} content={"<a href=\"https://www.facebook.com/unasnastatku\" target=\"_blank\">\"U nás na statku\"</a> – přímo ve Vsi - domácí výroba zavařenin, polévek, omáček, paštik, sirupů, kompotů atd., z lokálních potravin, <br>stačí se stavit kdykoliv na návštěvu..<br>~<br><a href=\"https://www.facebook.com/SpoluPekarstvi/\" target=\"_blank\">Rodinné Pekařství SPOLU</a> v Zásmukách - kvalitní domácí produkty, slané i sladké. Otevřeno mají út a čt, ale pečou každý den a po předchozí domluvě u nich lze <br>objednat i mimo otevírací dobu..<br>~<br><a href=\"https://www.facebook.com/profile.php?id=100087518436841\" target=\"_blank\">Hostinec u Jarošů</a> ve Svojšicích: Po – Pá výborné hotovky. Lze zakoupit čerstvé uzeniny, doporučujeme především buřty na oheň. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ra570k pb--60 pt--60" name={"[[UNIsectionname3]]"} style={{"marginTop":14}} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/e0c165b430c14dd39330ba640a87e9f6_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/e0c165b430c14dd39330ba640a87e9f6_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/e0c165b430c14dd39330ba640a87e9f6_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/e0c165b430c14dd39330ba640a87e9f6_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/e0c165b430c14dd39330ba640a87e9f6_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/e0c165b430c14dd39330ba640a87e9f6_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/e0c165b430c14dd39330ba640a87e9f6_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/e0c165b430c14dd39330ba640a87e9f6_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--16 pt--16" anim={"7"} animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">+420 608 859 625<br>chalupa.oresak@gmail.com<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"[[UNIsecname7]]"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">~ Užijte si pospolitost a společně prožitý čas ~<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":205}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Nová Ves III 44<br>Svojšice okr. Kolín 280 02<br>+420 608 859 625<br>chalupa.oresak@gmail.com<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}